import getMostSpecificAddressRegion from './get-most-specific-address';
import { getPositionFromDescription } from './google-maps/getPositionFromDescription';

import type Address from 'data/addresses';
import type { NextRouter } from 'next/router';
import type ListingParams from 'contexts/preferences/listing-params';
import { ProvinceOrStateCode } from './province_or_state';
import { provinceOrStateNameFromCode } from './province_or_state';
import { AddressType, SingleAddress } from '@zoocasa/go-search';
import { CountryCode, CountryCodeList } from 'types/countries';

/** @deprecated use {@link navigateToMapView} instead */
export default async function clickMapView(listingParams: ListingParams, addresses: readonly Address[], router: NextRouter, useUsListings?: boolean) {
  const push = router.push.bind(router);
  const addressRegion = getMostSpecificAddressRegion(addresses);
  if (addressRegion?.addressType === 'province') {
    listingParams.setZoom(7);
  } else {
    listingParams.setZoom(14);
  }
  if (addressRegion) {
    const formattedAddressSearchQuery = `${addressRegion.neighbourhood}, ${addressRegion.subDivision}, ${provinceOrStateNameFromCode(addressRegion.province.toLowerCase() as ProvinceOrStateCode)}, ${addressRegion.country}`.replaceAll('null, ', '');
    const data = await getPositionFromDescription(formattedAddressSearchQuery, useUsListings).catch(() => {
      window.location = '/search' as unknown as Location;
      return;
    });
    if (data) {
      const { latitude, longitude } = data;
      listingParams.setLatitude(latitude);
      listingParams.setLongitude(longitude);
      listingParams.setAreaName(formattedAddressSearchQuery.split(',')[0]);
    }
  }
  push('/search');
}

/**
 * Navigates to the map view for the given address.
 *
 * @param listingParams The listing parameters to set.
 * @param address The address to navigate to.
 * @param push The router push function.
 * @param countryFilter The country filter to use.
 * @returns A promise that resolves when the navigation is complete.
 */
export async function navigateToMapView(listingParams: ListingParams, address: SingleAddress, push: NextRouter['push'], countryFilter: CountryCode) {
  const zoomLevel = address.addressType === AddressType.ADDRESS_TYPE_PROVINCE_OR_STATE ? 7 : 14;
  const formattedAddressSearchQuery = [
    address.neighbourhood,
    address.subDivision,
    address.provinceOrState,
    address.country,
  ].filter(Boolean).join(', ');

  listingParams.setZoom(zoomLevel);

  try {
    const data = await getPositionFromDescription(formattedAddressSearchQuery, countryFilter === CountryCodeList.UNITED_STATES);
    if (data) {
      const { latitude, longitude } = data;
      listingParams.setLatitude(latitude);
      listingParams.setLongitude(longitude);
      listingParams.setAreaName(formattedAddressSearchQuery.split(',')[0]);
    }
  } catch {
    window.location = '/search' as unknown as Location;
    return;
  }
  push('/search');
}