import { AREA_PAGE_FILTER_OPTION_TO_GTM_EVENT } from 'constants/area-map-page-filters';
import {
  GTM_AREA_PAGE_MAP_CARD_FOURTH_CLICK,
  GTM_AREA_PAGE_MAP_CARD_FOURTH_VERSION_A,
  GTM_AREA_PAGE_MAP_CARD_FOURTH_VERSION_B,
  GTM_CLICK_AREA_PAGE_MAP_BUTTON,
  GTM_CLICK_AREA_PAGE_MORE_FILTERS_BUTTON,
  GTM_CLICK_AREA_PAGE_SAVE_SEARCH_BUTTON,
  GTM_VIEW_AREA_PAGE,
} from 'constants/events';
import {
  useUserContext,
} from 'contexts/user';
import {
  CREATE_SAVED_SEARCH_MODAL,
  LOGIN_REGISTRATION_MODAL,
  SAVED_SEARCH_FILTERS_MODAL,
  useModalContext,
} from 'contexts/modal';
import {
  AVAILABLE_STATUS,
  NOT_AVAILABLE_OTHER_STATUS,
  Filter,
  FilterKeys,
  Sort,
  DEFAULT_HAS_IMAGE_VALUE,
} from 'contexts/preferences/listing-params/types';
import { diff } from 'deep-object-diff';
import deepmerge from 'deepmerge';
import { cloneDeep } from 'lodash';
import { useRouter } from 'next/router';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { CountryCodeList } from 'types/countries';
import { hasFilterDeprecationBeenAck, setFilterDeprecationAck } from 'utils/ack_filter_deprecation';
import { hasNoImageListingsFilterBeenAck, setNoImageListingsFilterAck } from 'utils/ack_no_image_listings_filter';
import { getPositionFromDescription } from 'utils/google-maps/getPositionFromDescription';
import { trackEvent } from 'utils/google-tag-manager';
import { isClientSide } from 'utils/host-config';
import { hasDeprecatedHomeType } from 'utils/listing-query-helper';
import { isUsStateCode, ProvinceOrStateCode, provinceOrStateSlugFromCode } from 'utils/province_or_state';
import { navigateToMapView } from 'utils/redirect-to-map-view';
import { withEventTracking } from 'utils/track-event-hof';
import AreaListingsPageView, { AreaInsightsData, Pagination } from './area_listings_page_view';
import { AreaPageListingCardData } from './area_page_listing_card_data';
import getTargetedUrl, { TargetedUrlHelper } from './targeted-url-helper';
import { fromJSON } from './types/scroll-position';
import { DEFAULT_LISTING_PARAMS_FILTER_SHARED } from 'contexts/preferences/listing-params/defaults';
import { SCROLL_POSITION_KEY } from 'constants/session-storage';
import { useIsMobile } from 'hooks/use-size-class';
import { AddressType, SingleAddress, type AreaPageListing } from '@zoocasa/go-search';
import { useShowQuebecPopup } from 'hooks/use-quebec-popup';
import { useSearchResultClick } from 'hooks/use-search-result-click';
import { useCaptureLastSearch } from 'hooks/use-capture-last-search';
import { AREA_PAGE_IGNORE_PROFILE_SETTINGS_IMAGELESS_COOKIE_NAME, MLML_AGENT_COOKIE_NAME, MLML_LEAD_COOKIE_NAME } from 'constants/cookies';
import Cookies from 'js-cookie';
import { IGNORED_FILTER_PROPERTIES } from 'constants/filter';
import { usePreferencesContext } from 'contexts';

import type { ListingCardData } from 'components/listing-card';
import type { PartialDeep } from 'type-fest';
import type { AreaListingsPageViewModel } from './area_listings_page_view_model';

//#region Types
export interface AreaListingsPageControllerProps {
    viewModel: AreaListingsPageViewModel;
}
//#endregion

const areaPageListingToListingCardData = (listing: AreaPageListing) => (new AreaPageListingCardData(listing));

/** Memoized wrapper around the AreaListingsPageView component */
const PageView = memo(AreaListingsPageView);

const AreaListingsPageController = (props: AreaListingsPageControllerProps) => {
  const { viewModel: {
    requestedAddress,
    electedAddress,
    pagination: {
      page, count, total, size,
    },
    breadcrumbs,
    heading,
    areaGuideData,
    expandGuidesByDefault,
    internalLinks,
    seoLinks,
    listings,
    showMapCardExperimentView,
    myLinkMyLead = false,
  }} = props;
  const { listingParams } = usePreferencesContext();
  const { openModal } = useModalContext();
  const { user, isAuthenticated, siteLocation } = useUserContext();
  const router = useRouter();
  const { push } = router;
  const listingsData: ListingCardData[] = useMemo(() => listings.map((listing: AreaPageListing) => areaPageListingToListingCardData(listing)), [listings]);
  const paginationData: Pagination = { page: page + 1, size, count, total };
  const hasListings = count > 0;
  const showDisclaimer = electedAddress.countryCode === CountryCodeList.UNITED_STATES && hasListings;
  const isFallbackAreaPage = requestedAddress.slug !== electedAddress.slug;
  // Consolidate the filter from the requested address with the one stored in the listingParams
  if (requestedAddress.filter) {
    const consolidatedFilter = deepmerge(DEFAULT_LISTING_PARAMS_FILTER_SHARED, requestedAddress.filter as Filter);
    listingParams.setFilter(consolidatedFilter);
  } else {
    listingParams.setFilter(DEFAULT_LISTING_PARAMS_FILTER_SHARED);
  }
  if (requestedAddress.sort) {
    listingParams.setSort(requestedAddress.sort);
  }

  const isStatusAvailable = listingParams.filter.status === AVAILABLE_STATUS;
  const showGuides = (isStatusAvailable || hasListings) && !isFallbackAreaPage && areaGuideData !== null;

  let areaOverviewTitle: string | undefined;
  let areaName: string | undefined;
  let areaBlurb: string | undefined;
  let areaBlurbHeading: string | undefined;
  let areaNamedContent: string[] | undefined;
  let areaInsightsData: AreaInsightsData | undefined;

  if (showGuides) {
    areaOverviewTitle = generateAreaOverviewTitle(electedAddress);
    areaName = electedAddress.label;
    areaBlurb = areaGuideData?.areaBlurb;
    areaBlurbHeading = areaGuideData?.areaBlurbHeading;
    areaNamedContent = areaGuideData?.areaNamedContent;
    areaInsightsData = areaGuideData?.areaInsightsData;
  }

  const showFilterDeprecationWarningInitialState = (filter: PartialDeep<Filter>) => () => isClientSide() && !hasFilterDeprecationBeenAck() && hasDeprecatedHomeType(filter);
  const showNoImageListingsFilterWarningInitialState = (hasImageFilter: boolean = DEFAULT_HAS_IMAGE_VALUE) => () => isClientSide() && !hasNoImageListingsFilterBeenAck() && hasImageFilter;

  const [showFilterDeprecationWarning, setShowFilterDeprecationWarning] = useState(showFilterDeprecationWarningInitialState(listingParams.filter));
  const [showNoImageListingsFilterWarning, setShowNoImageListingsFilterWarning] = useState(showNoImageListingsFilterWarningInitialState(listingParams.filter.hasImage));

  let noListingsInAreaMessage: string | undefined;
  if (isFallbackAreaPage) {
    const requestedAddressLabel = [requestedAddress.neighbourhood?.name, requestedAddress.subDivision?.name, requestedAddress.provinceOrState?.name]
      .filter(Boolean)
      .join(', ');
    const electedAddressLabel = [electedAddress.neighbourhood, electedAddress.subDivision, electedAddress.provinceOrState]
      .filter(Boolean)
      .join(', ') || electedAddress.country;
    let grammar_prefix = '';
    if (electedAddressLabel == 'USA') {
      grammar_prefix = 'the ';
    }
    if (requestedAddressLabel && requestedAddressLabel.length > 0) {
      noListingsInAreaMessage = `0 Listings Found / We couldn't find any real estate listings in ${requestedAddressLabel}. Here are some listings in ${grammar_prefix}${electedAddressLabel}`;
    } else {
      noListingsInAreaMessage = `0 Listings Found / We couldn't find listings matching the criteria but here are some in ${grammar_prefix}${electedAddressLabel}...`;
    }
  }
  const internalLinksHeading = useIsMobile() ? 'Explore More Listings' : '';

  // we only want to run this once to restore the scroll position after the component's initial render
  useEffect(() => {
    withEventTracking(GTM_VIEW_AREA_PAGE, (restoreScrollPosition))();

    // We set this when user wants to temporarily disable (ignore) that user setting here in area page (instead of user profile)
    // ignoreProfileImagelessSetting is mainly for server side to detect when not to use the filter 'hasImage'
    const ignoreProfileImagelessSetting = Cookies.get(AREA_PAGE_IGNORE_PROFILE_SETTINGS_IMAGELESS_COOKIE_NAME);
    // We can clear it in client-side
    if (ignoreProfileImagelessSetting) Cookies.remove(AREA_PAGE_IGNORE_PROFILE_SETTINGS_IMAGELESS_COOKIE_NAME);
  }, []);

  const captureLastSearch = useCaptureLastSearch();
  const showQuebecPopup = useShowQuebecPopup();
  const hasQuebecContent = requestedAddress.provinceOrState?.slug === provinceOrStateSlugFromCode('qc');

  useEffect(() => {
    showQuebecPopup(hasQuebecContent, 'area');
  }, [hasQuebecContent, showQuebecPopup]);

  const onFiltersChanged = useCallback((changedFilters: PartialDeep<Filter>, changedFilter?: string) => {
    // Clean up any unwanted properties that trigger a filter change
    const sanitizedFilter = Object.keys(changedFilters)
      .filter(key => !IGNORED_FILTER_PROPERTIES.includes(key as FilterKeys))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: changedFilters[key as FilterKeys],
        };
      }, {} as PartialDeep<Filter>);

    const consolidatedFilter = deepmerge(listingParams.filter, sanitizedFilter);
    // We need to merge the consolidated filters with the default filter values since in a lot of places the code expects
    // all filter values to be set even if they are not relevant to the component accessing it.
    const listingParamsFilter = deepmerge(DEFAULT_LISTING_PARAMS_FILTER_SHARED, consolidatedFilter as Filter);
    const cookieFiltersClone: Filter = cloneDeep(listingParams.filter);
    const listingParamsFilterClone: Filter = cloneDeep(listingParamsFilter);
    IGNORED_FILTER_PROPERTIES.forEach(key => {
      delete cookieFiltersClone[key];
      delete listingParamsFilterClone[key];
    });

    const filterDiff = diff(cookieFiltersClone, listingParamsFilterClone) as Filter;

    if (filterDiff?.status) {
      const updatedStatus = filterDiff.status as string;
      if (updatedStatus === AVAILABLE_STATUS) {
        trackEvent(AREA_PAGE_FILTER_OPTION_TO_GTM_EVENT['active']);
      } else if (updatedStatus === NOT_AVAILABLE_OTHER_STATUS) {
        trackEvent(AREA_PAGE_FILTER_OPTION_TO_GTM_EVENT['expired']);
      }
    }

    // If user wants to temporarily turn on hasImage filter and ignore his/her user setting,
    // then we would detect a mismatch, and send a new cookie so when page loads (after the filter change),
    // we know not to use hasImage in user profile when fetching listings on server side
    if (filterDiff?.hasImage !== user?.hideImagelessListings) {
      Cookies.set(AREA_PAGE_IGNORE_PROFILE_SETTINGS_IMAGELESS_COOKIE_NAME, 'true');
    }

    if (Object.keys(filterDiff).length !== 0) {
      // This will trigger a rerender as this component is an observer for the Mobx storage and it is necessary because
      // when we navigate to the map page, we reset the smart url and go back to the /search route. That way the map component
      // depends on the listingParams to figure out the current filters
      listingParams.setFilter(listingParamsFilter);
      captureLastSearch(user?.id, listingParams);

      const targetedUrlConfig: TargetedUrlHelper = {
        url: `${window.location.origin}${window.location.pathname}`,
        filters: consolidatedFilter as Filter,
        sort: requestedAddress.sort,
        pageNumber: 1, // When filters change, we want to reset the page number to 1
        fallbackSlug: '',
      };

      const targetedPath = getTargetedUrl(targetedUrlConfig);
      push(targetedPath);
    }

    if (changedFilter) {
      trackEvent(AREA_PAGE_FILTER_OPTION_TO_GTM_EVENT[changedFilter]);
    }
  }, [listingParams, user?.id, requestedAddress.sort, push, captureLastSearch]);

  const onSortChanged = useCallback((newSort: Sort) => {
    if (requestedAddress.sort != newSort) {
      listingParams.setSort(newSort);
      captureLastSearch(user?.id, listingParams);
      const targetedUrlConfig: TargetedUrlHelper = {
        url: `${window.location.origin}${window.location.pathname}`,
        filters: listingParams.filter,
        sort: listingParams.sort,
        pageNumber: 1, // When sorting change, we want to reset the page number to 1
        fallbackSlug: isFallbackAreaPage ? electedAddress?.slug : '',
      };
      const targetedPath = getTargetedUrl(targetedUrlConfig);
      push(targetedPath);
    }
  }, [requestedAddress.sort, listingParams, captureLastSearch, user?.id, isFallbackAreaPage, electedAddress?.slug, push]);

  const setPageNumber = useCallback((pageNumber: number) => {
    if (pageNumber != paginationData.page) {
      const targetedUrlConfig: TargetedUrlHelper = {
        url: `${window.location.origin}${window.location.pathname}`,
        filters: listingParams.filter,
        sort: listingParams.sort,
        pageNumber,
        fallbackSlug: isFallbackAreaPage ? electedAddress?.slug : '',
      };
      const targetedPath = getTargetedUrl(targetedUrlConfig);
      push(targetedPath);
    }
  }, [paginationData.page, listingParams.filter, listingParams.sort, isFallbackAreaPage, electedAddress?.slug, push]);

  const handleDeprecationWarningButtonClicked = useCallback(() => {
    setFilterDeprecationAck(true);
    setShowFilterDeprecationWarning(false);
  }, [setShowFilterDeprecationWarning]);

  const handleNoImageListingsFilterWarningClose = useCallback(() => {
    setNoImageListingsFilterAck(true);
    setShowNoImageListingsFilterWarning(false);
  }, [setShowNoImageListingsFilterWarning]);

  // Handle tracking when we show the map card on the area page as part of a growthbook experiment
  const trackMapCardExperimentView = useCallback(() => {
    // Use the showMapCardExperimentView from the server side rather than the useExperiment hook from the client side
    // to determine what event to track since the experiment takes time to load on the client side, meaning the
    // base variation will get tracked even if the experiment is on
    if (showMapCardExperimentView) {
      trackEvent(GTM_AREA_PAGE_MAP_CARD_FOURTH_VERSION_B);
    }
    else {
      trackEvent(GTM_AREA_PAGE_MAP_CARD_FOURTH_VERSION_A);
    }
  }, [showMapCardExperimentView]);

  const trackMapCardClick = useCallback(() => {
    trackEvent(GTM_AREA_PAGE_MAP_CARD_FOURTH_CLICK);
  }, []);

  const openSavedSearchFiltersModal = useCallback(() => {
    openModal(SAVED_SEARCH_FILTERS_MODAL, {
      filter: listingParams.filter,
      onFiltersChanged,
      showNoImageListingsFilterWarning,
      onNoImageListingsFilterWarningClose: handleNoImageListingsFilterWarningClose,
    });
  }, [openModal, listingParams.filter, onFiltersChanged, showNoImageListingsFilterWarning, handleNoImageListingsFilterWarningClose]);

  const onMoreButtonClick = useCallback(() => {
    const handleMoreButtonClick = withEventTracking(GTM_CLICK_AREA_PAGE_MORE_FILTERS_BUTTON, openSavedSearchFiltersModal);
    handleMoreButtonClick();
  }, [openSavedSearchFiltersModal]);

  const onSaveSearchButtonClick = useCallback(() => {
    const handleSaveSearchButtonClick = withEventTracking(GTM_CLICK_AREA_PAGE_SAVE_SEARCH_BUTTON, () => {
      if (isAuthenticated) {
        const saveWithFetchedPosition = async () => { // Sets position if user lands on area page with URL
          if (listingParams.filter.slug) {
            const position = await getPositionFromDescription(listingParams.filter.slug, true);
            const latitude = position?.latitude;
            const longitude = position?.longitude;
            if (latitude && longitude) {
              listingParams.setFilter({
                ...listingParams.filter,
                latitude,
                longitude,
              });
              openModal(CREATE_SAVED_SEARCH_MODAL, { query: { ...listingParams, filter: { ...listingParams.filter, boundary: null, latitude, longitude }}});
            }
          }
        };
        saveWithFetchedPosition();
      } else {
        openModal(LOGIN_REGISTRATION_MODAL);
      }
    });
    handleSaveSearchButtonClick();
  }, [isAuthenticated, openModal, listingParams]);
  const onSearchResultClick = useSearchResultClick();

  const handleMapIconClick = withEventTracking(GTM_CLICK_AREA_PAGE_MAP_BUTTON, () => {
    navigateToMapView(listingParams, electedAddress, push, siteLocation);
  });

  let showsMyLinkMyLeadCreate = false;
  let showsMyLinkMyLead = false;

  if (myLinkMyLead) {
    showsMyLinkMyLeadCreate = !!Cookies.get(MLML_AGENT_COOKIE_NAME);
    showsMyLinkMyLead = !!Cookies.get(MLML_LEAD_COOKIE_NAME);
  }

  return (<PageView
    electedAddress={electedAddress}
    listingParams={listingParams}
    listings={listingsData}
    breadcrumbs={breadcrumbs}
    pagination={paginationData}
    sort={requestedAddress.sort}
    heading={heading}
    areaName={areaName}
    areaBlurb={areaBlurb}
    areaBlurbHeading={areaBlurbHeading}
    areaOverviewTitle={areaOverviewTitle}
    areaNamedContent={areaNamedContent}
    areaInsightsData={areaInsightsData}
    internalLinks={internalLinks}
    internalLinksHeading={internalLinksHeading}
    seoLinks={seoLinks}
    noListingsInAreaMessage={noListingsInAreaMessage}
    hasNoListingsInArea={isFallbackAreaPage}
    expandGuidesByDefault={expandGuidesByDefault}
    showGuides={showGuides}
    showDisclaimer={showDisclaimer}
    setPageNumber={setPageNumber}
    onSortChanged={onSortChanged}
    onMobileFilterButtonClick={openSavedSearchFiltersModal}
    onValueChange={onFiltersChanged}
    onSaveSearchButtonClick={onSaveSearchButtonClick}
    onLocationSearchClick={onSearchResultClick}
    onMapIconClick={handleMapIconClick}
    onMoreButtonClick={onMoreButtonClick}
    showFilterDeprecationWarning={showFilterDeprecationWarning}
    showNoImageListingsFilterWarning={showNoImageListingsFilterWarning}
    onDeprecationWarningButtonClick={handleDeprecationWarningButtonClicked}
    onNoImageListingsFilterWarningClose={handleNoImageListingsFilterWarningClose}
    showMapCardExperimentView={showMapCardExperimentView}
    trackMapCardExperimentView={trackMapCardExperimentView}
    trackMapCardClick={trackMapCardClick}
    showsMyLinkMyLeadCreate={showsMyLinkMyLeadCreate}
    showsMyLinkMyLead={showsMyLinkMyLead}
  />);
};


//#region Helper functions
function generateAreaOverviewTitle(address: SingleAddress) {
  if (address.addressType === AddressType.ADDRESS_TYPE_PROVINCE_OR_STATE) {
    return isUsStateCode(address.provinceOrState?.toUpperCase() as ProvinceOrStateCode) ? 'State Guide' : 'Province Guide';
  } else if (address.addressType === AddressType.ADDRESS_TYPE_NEIGHBOURHOOD) {
    return 'Neighbourhood Guide';
  } else {
    return 'City Guide';
  }
}

/**
 * Restores the scroll position from the session storage if the current area page matches the area page
 * that we went to an address page from.
 */
function restoreScrollPosition() {
  const scrollPosition = sessionStorage.getItem(SCROLL_POSITION_KEY);
  if (scrollPosition) {
    const scrollPositionObject = fromJSON(scrollPosition);
    if (window?.location?.pathname === scrollPositionObject?.pathname) {
      window.scrollTo(0, scrollPositionObject.position);
    }
  }
}
//#endregion
export default AreaListingsPageController;

export { AreaListingsPageController };